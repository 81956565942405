@font-face {
    font-family: "VelaSans";
    src: url("fonts/VelaSans-Regular.woff2") format("woff2"),
    url("fonts/VelaSans-Regular.woff") format("woff"),
    url("fonts/VelaSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
    font-variant: normal;
}

@font-face {
    font-family: "VelaSans";
    src: url("fonts/VelaSans-SemiBold.woff2") format("woff2"),
    url("fonts/VelaSans-SemiBold.woff") format("woff"),
    url("fonts/VelaSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
    font-variant: normal;
}

@font-face {
    font-family: "VelaSans";
    src: url("fonts/VelaSans-Bold.woff2") format("woff2"),
    url("fonts/VelaSans-Bold.woff") format("woff"),
    url("fonts/VelaSans-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
    font-variant: normal;
}

@font-face {
    font-family: "VelaSans";
    src: url("fonts/VelaSans-ExtraBold.woff2") format("woff2"),
    url("fonts/VelaSans-ExtraBold.woff") format("woff"),
    url("fonts/VelaSans-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@mixin breakpoint ($point) {
    @if $point == 'sm' {
        @media (min-width: 576px) { @content; }
    }
    @else if $point == 'md' {
        @media (min-width: 768px) { @content; }
    }
    @else if $point == 'lg' {
        @media (min-width: 992px) { @content; }
    }
    @else if $point == 'xl' {
        @media (min-width: 1200px) { @content; }
    }
    @else if $point == 'xxl' {
        @media (min-width: 1400px) { @content; }
    }
    @else if $point {
        @media (min-width: $point) { @content; }
    }
}

$text-primary: #1E2431;
$text-secondary: #464E5C;
$primary: #50AF87;
$secondary: #555555;

html {
    font-family: 'VelaSans', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: $text-primary;
    line-height: 1.7;
    overflow-x: hidden;
}

body {
}

section {
    padding: 3.35rem 0;
}

h1 {
    font-weight: 800;
    font-size: 1.85rem;
    line-height: 1.25;
    letter-spacing: -0.015em;
    margin: 0;
}

h2 {
    margin-top: 2rem;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.375;
}

h3 {
    margin: 0.55rem 0;
    padding-top: 1rem;
    font-style: normal;
    font-weight: 700;
    font-size: 0.95rem;
    line-height: 1.35rem;
    text-transform: uppercase;
}
h4 {
    margin: 0.55rem 0;
    font-weight: 700;
    font-size: 1.35rem;
    line-height: 1.35;
}

@include breakpoint("md") {
    html {
        font-size: 18px;
        line-height: 1.65;
    }
    h1 {
        font-size: 2.2rem;
        line-height: 1.125;
    }
    h2 {
        font-size: 1.75rem;
        line-height: 1.3;
    }
    h3 {
        font-size: 1.35rem;
        line-height: 1.375;
    }
    h4 {
        font-size: 1.65rem;
        line-height: 1.36;
    }
    h5 {
        font-size: 1.2rem;
        line-height: 1.36;
    }
    .btn.btn-group {
        max-width: 402px;
    }
}

@include breakpoint("1010px") {
    h1 {
        font-size: 2.65rem;
        line-height: 1.125;
    }
    #header {
        display: flex;
        position: static;
        justify-content: space-between;
        width: 100%;
        max-width: 1120px;
        margin: auto;
    }
}

a {
    text-decoration: none;
    font-size: 0.9rem;
    color: $text-secondary;
}

ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

strong {
    color: $primary;
}

.container {
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 1em;
}

.df {
    display: flex;
}
.jc-sb {
    justify-content: space-between;
}
.jc-se {
    justify-content: space-evenly;
}
.jc-e {
    justify-content: end;
}
.fd-r {
    flex-direction: row;
}
.fd-c {
    flex-direction: column;
}


.btn-group {
    display: flex;
    justify-content: space-between;
    max-width: 352px;
}
.btn-primary {
    display: inline-block;
    text-align: center;
    padding: 17px 20px;
    font-weight: 600;
    font-size: 1rem;
    line-height: 22px;
    color: white;
    //min-width: 163px;
    //height: 56px;
    background: linear-gradient(96.87deg, #31BA89 4.93%, #2896E5 125.43%);
    box-shadow: 0px 4px 13px rgba(44, 162, 200, 0.22);
    border-radius: 16px;
    border: 0;
    margin: 27px 18px 27px 0;
    cursor: pointer;
}
.btn-secondary {
    display: inline-block;
    text-align: center;
    padding: 17px 20px;
    font-weight: 600;
    font-size: 1rem;
    line-height: 22px;
    color: #464E5C;
    //min-width: 163px;
    //height: 56px;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin: 27px 0;
    cursor: pointer;
}

.blur {
    width: 100%;
    height: 100%;
    -webkit-filter: blur(1px);
    filter: blur(1px);
    opacity: 0.5;
}


.wrapper {
    min-height: 100vh;
    display: grid;
    grid-template: minmax(70px, auto) 1fr minmax(70px, auto) / 1fr;
    grid-template-areas:
        "header"
        "main"
        "footer";

    .header {
        z-index: 100;
        padding-top: 18px;
        grid-area: header;
        display: grid;
        grid-gap: 1rem;
        //
        //width: 100%;
        grid-template: 1fr / 1fr minmax(auto, 150px) auto minmax(auto, 698px) 1fr;
        position: fixed;
        transition: .3s;
        top: 0;
        left: 0;
        align-items: center;
        //background-color: white;

        .logo {
            grid-column: 2/3;
        }

        .language {
            grid-column: 3/4;
            //justify-content: right;
            #language {
                //appearance: none;
                display: inline-block;
                color: $text-secondary;
                font-size: 12px;
                line-height: 16px;
                padding: 4px 0 4px 12px;
                width: 64px;
                height: 32px;
                background: #FFFFFF;
                border: 1px solid #E9E9E9;
                box-sizing: border-box;
                border-radius: 11px;
            }
            //select>option {
            //    font-size: 12px;
            //}
        }

        .menu {
            grid-column: 4/5;
            display: grid;
            justify-items: end;
            .menu_list {
                display: none;
                max-height: 80vh;
                flex-direction: column;
                position: absolute;
                left: 0;
                right: 0;
                top: 75px;
                width: 80%;
                margin: auto;
                font-weight: 700;
                background: #FFFFFF;
                box-shadow: 0 4px 14px rgba(95, 95, 95, 0.25);
                border-radius: 12px;
                overflow-y: scroll;
                -webkit-overflow-scrolling:touch;
                .link {
                    padding: 13px 0 13px 23px;
                    display: block;
                }
                ul> li {
                    font-weight: 400;
                    a {
                        display: block;
                        padding: 6px 0 6px 33px;
                    }
                }
                &.opened {
                    display: flex;
                }
                hr {
                    width: 90%;
                    margin: 0 auto;
                    padding: 0;
                }
                .sub-menu_list {
                    margin-bottom: 20px;
                }
                .authorisation {
                    display: flex;
                    justify-content: space-around;
                    .btn-primary, .btn-secondary {
                        padding: 5px 25px;
                    }
                }
            }

            .toggle_btn {
                width: 30px;
                opacity: .4;
                display: grid;
                align-items: center;
                justify-items: center;
                grid-template: repeat(3, 8px) / 1fr;
                cursor: pointer;
                transition: .1s linear;

                span {
                    height: 2px;
                    width: 30px;
                    background-color: $text-primary;
                }

                &[aria-expanded="true"] {
                    transform: scale(0.7);
                }
            }

            .toggle_btn::before {
                content: "";
                height: 2px;
                width: 20px;
                background-color: $text-primary;
            }

            .toggle_btn::after {
                content: "";
                height: 2px;
                width: 20px;
                background-color: $text-primary;
            }

            @include breakpoint("1010px"){
                //@include breakpoint("md"){
                .toggle_btn {
                    display: none;
                }
                i {
                    border: solid black;
                    border-width: 0 1px 1px 0;
                    display: inline-block;
                    padding: 2.5px;
                }

                .down {
                    //position: absolute;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    margin-bottom: 5px;
                    margin-left: 3px;
                }
                .menu_list {
                    position: static;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    font-size: 0.8rem;
                    font-weight: 400;
                    align-items: center;
                    box-shadow: none;
                    padding: 0;
                    hr {
                        display: none;
                    }
                    .btn-primary, .btn-secondary {
                        margin: 0 0 0 16px;
                        font-size: 0.8rem;
                        box-shadow: none;
                    }
                    li>a:hover {
                        color: $primary;
                    }
                    // on hover
                    li:hover .sub-menu_list {
                        display: block;
                    }

                    // for touch
                    .sub-menu_list.opened{
                        display: block;
                    }
                    .sub-menu_list {
                        display: none;
                        position: absolute;
                        background: rgba(266, 255, 255, .5);
                        padding-right: 10px;
                        a {
                            padding-left: 22px;
                        }
                    }
                }

            }

        }

    }


    .main {
        grid-area: main;
        overflow-x: hidden;
        background-image: url("../img/bg/green_shadow.svg"), url("../img/bg/orange_shadow.svg");
        background-repeat: no-repeat, no-repeat;
        background-position: top -50px left 0, right 80px;

        @include breakpoint("md"){
            background-image:
                url("../img/bg/main-bg-line1.svg"),
                url("../img/bg/main-bg-line2.svg"),
                url("../img/bg/main-bg-circul.svg"),
                url("../img/bg/main-bg-point.svg"),
                url("../img/bg/main-bg-left.svg"),
                url("../img/bg/main-bg-center.svg"),
                url("../img/bg/main-bg-right.svg");
            background-repeat:
                no-repeat,
                no-repeat,
                no-repeat,
                no-repeat,
                no-repeat,
                no-repeat,
                no-repeat;
            background-position:
                top 70px left 0,
                top 160px left 0,
                top 140px left 72px,
                top 164px left 304px,
                top left -50px,
                top 70px center,
                top 140px right -50px;
            background-size:
                contain,
                contain,
                68px,
                6px,
                515px 650px,
                515px 650px,
                715px 650px;

        }

        .offer {
            .cta {
                span {
                    margin: 0.55rem 0;
                    padding-top: 1rem;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 0.95rem;
                    line-height: 1.35rem;
                    text-transform: uppercase;
                }
            }
            //margin: 70px 0 3.125rem;
            @include breakpoint("md") {
                max-width: 1120px;
                margin: auto;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-around;
                align-items: center;
                //.container {
                //    padding: 0;
                //}
                .cta {
                    width: 486px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    span {
                        font-size: 1.35rem;
                        line-height: 1.375;
                    }
                }
                .main_images {

                }
            }
        }


        .main_images, .card_images{
            transform: rotate(-9.2deg);
            display: flex;
            justify-content: center;
            margin-bottom: 70px;

            .title {
                margin: 0;
                font-size: 8px;
                line-height: 11px;
                text-transform: uppercase;
            }
            .placeholder {
                margin: 0;
                //font-weight: 700;
                font-size: 14px;
                line-height: 14px;
            }

            .card-header {
                margin: 4px 8px;
                .title {
                    text-align: end;
                }
                .placeholder {
                    text-align: end;
                }
            }
            .gpay {
                height: 246px !important;
                img {
                    border-radius: 0 0 8px 8px;
                }
                hr {
                    margin: 0;
                }
                .gpay-card-header {
                    margin: 12px 12px 10px 9px;
                    align-items: center;
                    .logo-header {
                        margin: 0 12px;
                        font-size: 14px;
                        font-weight: 700;
                    }
                }
                .program {
                    padding: 0 8px;
                    p {
                        margin: 6px 0 12px;
                    }
                }
                .barcode .code128 {
                    margin-top: 12px;
                }
            }

            .strip {
                margin: 8px 0 8px 0;
            }
            .cardholder {
                margin-left: 8px;
            }
            .barcode {
                justify-content: center;
                align-self: end;
                .qr {
                    margin-top: 50px;
                }
                .code128 {
                    margin-top: 80px;
                }
                .pdf417 {
                    margin-top: 80px;
                }
            }

            .first_card {
                min-width: 200px;
                margin: 10px;
                width: 200px;
                height: 294px;
                background: #ffffff;
                box-shadow: 0px 4px 23px rgba(96, 96, 96, 0.25);
                border-radius: 12px;
            }
            .second_card {
                margin: 10px;
                min-width: 200px;
                width: 200px;
                height: 294px;
                background: #ffffff;
                box-shadow: 0px 4px 23px rgba(96, 96, 96, 0.25);
                border-radius: 12px;
            }
            .third_card {
                margin: 10px;
                min-width: 200px;
                width: 200px;
                height: 294px;
                background: #ffffff;
                box-shadow: 0px 4px 23px rgba(96, 96, 96, 0.25);
                border-radius: 12px;
            }
            @include breakpoint("md"){
                transform: none;
                position: relative;
                width: 640px;
                height: 664px;
                .third_card {
                    width: 260px;
                    height: 386px;
                    position: absolute;
                    left: 376px;
                    top: 0.28px;
                }
                .second_card {
                    width: 260px;
                    height: 386px;
                    position: absolute;
                    left: 190px;
                    top: 90px;
                    transform: rotate(-10.7deg);
                }
                .first_card {
                    width: 260px;
                    height: 386px;
                    position: absolute;
                    left: 50px;
                    top: 249.5px;
                    transform: rotate(-21.47deg);
                }
                .card-header {
                    img {
                        height: 34px;
                        width: auto;
                    }
                }
                .strip {
                    img {
                        width: 260px;
                        height: auto;
                    }
                }
                .barcode {
                    .qr {
                        margin-top: 76px;
                        width: 116px;
                        height: 119px;
                    }
                    .code128 {
                        margin-top: 119px;
                        width: 178px;
                        height: 63px;
                    }
                    .pdf417 {
                        margin-top: 115px;
                        width: 182px;
                        height: 67px;
                    }
                }
                .title {
                    font-size: 10px;
                    line-height: 14px;
                }
                .placeholder {
                    font-size: 18px;
                    line-height: 19px;
                }
            }

        }

        .visit-card {
            @include breakpoint("md"){
                background-image:
                    url("../img/bg/visit-card-bg-left.svg"),
                    url("../img/bg/visit-card-bg-right.svg");
                background-repeat:
                    no-repeat,
                    no-repeat;
                background-position:
                    top left,
                    top right -100px;
                background-size:
                    515px 650px,
                    715px 650px;
                .container {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    align-items: center;
                    .push-image {
                        margin: 0;
                        .bg {
                            //width: 449px;
                            //height: 438px;
                            img {
                                //width: 339px;
                                //height: 504px;
                            }
                        }
                    }
                    .visit-card-text {
                        max-width: 515px;
                    }
                }

            }
        }
        .push-image {
            position: relative;
            max-width: 375px;
            margin: 150px auto 0;

            .ellipse-small {
                position: absolute;
                width: 17px;
                height: 17px;
                left: 0;
                top: -43px;
                border-radius: 50%;
                background: rgba(249, 191, 57, 0.27);
            }
            .ellipse-big {
                position: absolute;
                width: 38px;
                height: 38px;
                right: -38px;
                top: 240px;
                border-radius: 50%;
                background: rgba(249, 191, 57, 0.27);
            }

            .bg {
                margin: auto;
                width: 309px;
                height: 302px;
                background: linear-gradient(180deg, #F8BF2E 0%, #FFA386 100%);
                border-radius: 45px;

                img {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                }
            }
        }

        .client-orientation {
            @include breakpoint("md") {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .text {
                    a {
                        display: none;
                    }
                }
                .orange-box {
                    //max-width: 447px;
                    margin-right: 100px;
                    a {
                        display: block;
                    }
                }
            }
        }
        .orange-box {
            margin: 0 auto;
            color: white;
            background: linear-gradient(180deg, #F8BF2E 0%, #FFA386 100%);
            border-radius: 24px;
            padding-bottom: 15px;
            h2 {
                padding-top: 24px;
                font-size: 1.35rem;
                line-height: 1.35;
            }
            h3 {
                opacity: 0.6;
            }
            a {
                display: none;
            }
        }

        .bg-gray {
            //margin: 50px auto 25px;
            padding: 20px 0 50px;
            background: #F9FCFB;
            @include breakpoint("md"){
                background-color: #ffffff;
                background-image:
                    url("../img/bg/visit-card-bg-left.svg"),
                    url("../img/bg/visit-card-bg-right.svg");
                background-repeat:
                    no-repeat,
                    no-repeat;
                background-position:
                    top -200px left,
                    top -200px right -100px;
                background-size:
                    515px 650px,
                    715px 650px;
            }
        }

        .passes {
            background-image: url("../img/bg/green_shadow.svg"), url("../img/bg/green_shadow_right.svg");
            background-repeat: no-repeat, no-repeat;
            background-position: left top -75px, right top 75px ;
            @include breakpoint("md") {
                background-image:
                    url("../img/bg/passes-bg-line1.svg"),
                    url("../img/bg/passes-bg-line2.svg"),
                    url("../img/bg/green_shadow.svg"),
                    url("../img/bg/green_shadow_right.svg");
                background-repeat:
                    no-repeat,
                    no-repeat,
                    no-repeat,
                    no-repeat;
                background-position:
                    top left 0,
                    top 50px left 0,
                    left -150px top -75px,
                    right -150px top 75px;
                background-size:
                    contain,
                    contain,
                    600px 700px,
                    600px 700px;
                .container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                h2 {
                    max-width: 320px;
                    text-align: center;
                }
                .aw-gpay-logo {
                    order: -1;
                }
                .img-raw {
                    position: absolute;
                }
                .passes-content {
                    max-width: 464px;
                    text-align: center;
                    z-index: 1;
                }

                .card_images {
                    min-width: 1180px;
                    display: flex;
                    justify-content: space-between;
                    transform: none;
                    .first_card {
                        position: static;
                        margin-top: 230px;
                        transform: rotate(-13.16deg);
                    }
                    .second_card {
                        position: static;
                        margin-top: 80px;
                        transform: rotate(7.81deg);
                    }
                }
            }
        }

        .img-raw {
            position: relative;
        }

        .card_images{
            transform: rotate(-11.19deg);
            display: flex;
            justify-content: start;
            margin-bottom: 70px;
        }

        .aw-gpay-logo {
            display: flex;
            align-items: end;
            img {
                margin-right: 30px;
            }

        }

        .viber-telegram {
            color: white;
            background: linear-gradient(180deg, #58C3FF 0%, #A670FF 100%);
            border-radius: 24px;
            h2 {
                padding-top: 24px;
                font-size: 1.35rem;
                line-height: 1.35;
            }
        }

        .viber-telegram-content {
            @include breakpoint("md") {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: center;
                .viber-telegram-text {
                    max-width: 576px;
                    margin-right: 50px;
                }
                .viber-telegram-images {
                    margin: 0;
                    //width: 448px;
                    //height: 427px;
                }
            }
        }

        .viber-telegram-images {
            position: relative;
            width: 300px;
            height: 300px;
            margin: 30px auto;
            #viber {
                position: absolute;
                top: 0px;
                left: -25px;
            }
            #telegram {
                position: absolute;
                top: -10px;
                left: 75px;
            }
            .circle-sm {
                height: 17px;
                width: 17px;
                position: absolute;
                top: 50px;
                left: -10px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.27);
            }
            .circle-lg {
                height: 38px;
                width: 38px;
                position: absolute;
                bottom: 20px;
                right: 20px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.27);
            }
        }

        .loyalty {
            @include breakpoint("md") {
                background-image: url("../img/bg/bonus-bg-right.svg");
                background-repeat: no-repeat;
                background-position: right top 75px;
                background-size: 600px 700px;
                .container {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    .loyalty-images {
                        margin: 0;
                    }
                    .loyalty-text {
                        max-width: 576px;
                    }
                }
            }
        }

        .loyalty-images {
            position: relative;
            max-width: 375px;
            margin: 70px auto;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                margin: 20px 0 0 20px;
            }
            .circle-sm {
                position: absolute;
                width: 17px;
                height: 17px;
                left: 283px;
                top: -30px;
                border-radius: 50%;

                background: rgba(249, 191, 57, 0.27);
            }
            .circle-md {
                position: absolute;
                width: 33px;
                height: 33px;
                left: 30px;
                top: 0;
                border-radius: 50%;
                border: white 1px solid;
            }
            .circle-lg {
                position: absolute;
                width: 38px;
                height: 38px;
                right: -19px;
                top: 140px;
                border-radius: 50%;

                background: rgba(249, 191, 57, 0.27);
            }
        }

        .solution {
            @include breakpoint("md") {
                background-image: url("../img/bg/bonus-bg-right.svg");
                background-repeat: no-repeat;
                background-position: right 50px top -100px;
                background-size: 600px 600px;
                .container {
                    width: 100%;

                    .container {
                        max-width: 750px;
                        text-align: center;
                    }
                }
            }
        }

        .tariff-main {
            @include breakpoint("md") {
                background-image: url("../img/bg/tariff-bg-right.svg");
                background-repeat: no-repeat;
                background-position: right -150px bottom -100px;
                //background-size: 487px 617px;
                .main-container {
                    max-width: 1120px;
                    margin: 100px auto;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        max-width: 360px;
                        margin: 50px auto;
                    }

                    .cards {
                        max-width: 680px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .card {
                            width: 327px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                            background: #fff;

                            .tariff-general {
                                width: 90%;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
            font-size: 16px;
            line-height: 22px;
            h2 {
                text-align: center;
            }
            .card {
                margin-bottom: 16px;
                min-height: 150px;
                .tariff-general {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                }

                /* white */
                //background: #FFFFFF;
                /* grey */
                border: 1px solid #E9E9E9;
                box-sizing: border-box;
                /* shadow-xs */
                box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                p {
                    max-width: 276px;
                }
                .xl {
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 36px;
                }
                button {
                    color: $text-primary;
                    border: 0;
                    min-width: 149px;
                    height: 35px;
                    background: rgba(80, 175, 135, 0.05);
                    border-radius: 54px;
                }
                .btn-final {
                    margin: 16px auto;
                    width: 311px;
                    height: 44px;
                }
            }
        }

        .tariff {
            h3 {
                font-size: 1rem;
                color: $primary;
                font-weight: 600;
            }
            h4 {
                font-size: 1rem;
            }
            @include breakpoint("md") {
                background-image: url("../img/bg/tariff-bg-right.svg");
                background-repeat: no-repeat;
                background-position: right -150px bottom -100px;
                //background-size: 487px 617px;
                .main-container {
                    max-width: 1120px;
                    margin: 50px auto;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        max-width: 450px;
                        margin: 50px auto;
                    }

                    .cards {
                        max-width: 680px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .card {
                            width: 327px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                            background: #fff;

                            //.tariff-general {
                            //    width: 100%;
                            //    margin: 0 auto;
                            //    box-sizing: border-box;
                            ////}
                            &:first-child {
                                width: 100%;
                                margin-bottom: 50px;
                                padding-bottom: 16px;
                                p {
                                    margin: 4px 0 4px;
                                }
                            }
                        }
                    }
                }
            }
            font-size: 16px;
            line-height: 22px;
            h2 {
                text-align: center;
            }
            .card {
                margin-bottom: 16px;
                min-height: 150px;

                .container {
                    box-sizing: border-box;
                    width: 100%;
                }
                .tariff-general {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 30px;
                    position: relative;
                }

                &:first-child {
                    width: 100%;
                    margin-bottom: 50px;
                    padding-bottom: 16px;
                    p {
                        margin: 4px 0 4px;
                    }
                    .tariff-general {
                        padding-top: 12px;

                    }
                }

                /* white */
                background: #FFFFFF;
                /* grey */
                border: 1px solid #E9E9E9;
                box-sizing: border-box;
                /* shadow-xs */
                box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                .lg {
                    font-weight: 600;
                    font-size: 1.2rem;
                }
                .xl {
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 36px;
                }
                button {
                    color: $text-primary;
                    border: 0;
                    min-width: 149px;
                    height: 35px;
                    background: rgba(80, 175, 135, 0.05);
                    border-radius: 54px;
                }
                .btn-final {
                    margin: 16px auto;
                    width: 311px;
                    height: 44px;
                }
            }
        }



        .integration {
            transform: rotate(-9.2deg);
            margin-bottom: 70px;

            img {
                width: 100%;
                max-width: 640px;
                height: auto;
            }
            @include breakpoint("md"){
                transform: rotate(0)
            }
        }

        //.wallet {
        //    img {
        //        width: 100%;
        //        max-width: 640px;
        //        height: auto;
        //    }
        //}
        .wallet-block {
            background: #D9D6CD;
            border-radius: 24px;
            padding: 24px 24px 100px;
            .blue-box {
                margin: 0 auto;
                color: white;
                background: #3B97C9;
                border-radius: 24px;
                padding-bottom: 50px;
                h2 {
                    padding-top: 24px;
                    font-size: 1.35rem;
                    line-height: 1.35;
                }
                h3 {
                    opacity: 0.7;
                }
            }
            .yellow-box {
                margin: 0 auto;
                color: white;
                background: #FFAF00;
                border-radius: 24px;
                padding-bottom: 50px;
                h2 {
                    padding-top: 24px;
                    font-size: 1.35rem;
                    line-height: 1.35;
                }
                h3 {
                    margin-top: -40px;
                    opacity: 0.7;
                }
            }
            .green-box {
                margin: 0 auto;
                color: white;
                background: #50B93C;
                border-radius: 24px;
                padding-bottom: 50px;
                h2 {
                    padding-top: 24px;
                    font-size: 1.35rem;
                    line-height: 1.35;
                }
                h3 {
                    margin-top: -40px;
                    opacity: 0.7;
                }
            }
            .coral-box {
                margin: 0 auto;
                color: white;
                background: #F06E5F;
                border-radius: 24px 24px 0 0;
                padding-bottom: 15px;
                h2 {
                    padding-top: 24px;
                    font-size: 1.35rem;
                    line-height: 1.35;
                }
                h3 {
                    margin-top: -40px;
                    opacity: .7;
                }
            }
        }

        .viber, .telegram, .benefits, .wallet, .google {
            img {
                width: 100%;
                max-width: 640px;
                height: auto;
            }
            h2 {
                margin: 0 auto 2rem;
                max-width: 800px;
                text-align: center;
            }
            .card {
                margin-bottom: 16px;
                min-height: 150px;
                border: 1px solid #E9E9E9;
                box-sizing: border-box;
                box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
                border-radius: 12px;
            }
            @include breakpoint("md"){
                .cards {
                    display: flex;
                    flex-wrap: wrap;
                }
                .card {
                    width: 500px;
                    text-align: center;
                    background: #fff;
                    //display: flex;
                    //align-items: center;
                    //flex-direction: column;
                }
            }
        }


        //display: grid;
        //grid-template: 1fr / 1fr minmax(auto, 940px) minmax(auto, 940px) 1fr;
        //grid-gap: 20px;
        //.content {
        //grid-column: 2/4;

        //.main_screen {
        //    .system_items{
        //        display: grid;
        //        grid-template-columns: repeat(4, auto);
        //        justify-items: center;
        //    }
        //    .system_item{
        //        display: grid;
        //        grid-auto-flow: row;
        //        grid-template: auto 1fr / 1fr;
        //        justify-items: center;
        //    }
        //    .system_name {
        //        font-size: 1.25rem;
        //        padding-top: 1rem;
        //        text-decoration: none;
        //        color: #222222;
        //    }
        //}
        //}

        .registration {
            min-height: 60vh;
            width:260px;
            margin: auto;
            //padding: 20px;
            *, *:before, *:after {
                box-sizing: border-box;
            }
            .btn {
                width: 100%;
            }
            h2 {
                text-align: center;
                margin-bottom: 30px;
            }
            .checkbox {
                margin: 10px 0;
                label {
                    display: inline-block;
                    cursor: pointer;
                }
            }
            .checkbox input[type=checkbox] {
                margin-right: 10px;
            }
            input:not([type=checkbox]), select {
                width:100%;
                padding:10px;
                margin:10px 0;
                border:0;
                box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
                border-radius: 10px;
                -webkit-appearance: none;
                color: $text-primary;
                background: #fff;
                height: 44px;
            }
            select:required:invalid {
                color: #a7a7a7;
            }

            #phone {
                padding-left: 50px;
            }
            .phone.error {
                border: #f06e5f solid 1px;
            }
            .iti {
                width: 100%;
                padding: 10px 0;
            }
            .iti--allow-dropdown .iti__selected-flag {
                background-color: transparent;
            }
        }

        .example-bot {
            width: 100%;
            //align-items: center;
            text-align: center;
            a {
                font-size: 1rem;
                font-weight: 600;
                color: $primary;
                text-decoration: underline;
            }

            @include breakpoint("md") {
                margin: 100px auto 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row-reverse;
            }
        }

        .contacts .card {
            text-align: center;
            width: 300px;
            padding: 36px;
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            background: #fff;
            border-radius: 40px;
            box-shadow: 0px 4px 23px rgba(96, 96, 96, 0.25);
            a {
                color: $primary;
            }
        }

        .public-offer {
            ul {
                list-style-type: circle;
                margin-left: 2rem;
            }
            .companyDetails {
                list-style: none;
            }
            ol {
                margin: 1rem auto 2rem;
            }
        }


    }

    .noselect {
        -moz-user-select: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        -ms-user-select: none;
        user-select: none;
    }

    .webhook, .cooperation, .public-offer, .about {
        width: 100%;
        box-sizing: border-box;
        margin: 30px auto;
        padding: 18px;
        background: #fff;
        border-radius: 40px;
        box-shadow: 0px 4px 23px rgba(96, 96, 96, 0.25);

        @include breakpoint("1010px"){
            margin: 100px auto;
        }
    }

    .businesses {
        display: flex;
        flex-flow: row wrap;
        align-content: space-evenly;
        justify-content: space-evenly;
        margin: 3rem 0;
        .card {
            padding: 1rem;
            border-radius: 20px;
            border: 1px solid $primary
        }
    }

    .currency {
        position: absolute;
        top: -.4rem;
        right: 1rem;
        //color: $primary;
        color: #3B8164;
        font-size: .9rem;
    }





    .footer {
        grid-area: footer;
        background: white;
        //width: 100%;
        //line-height: 34px;

        a {
            padding: 12px 0;
            display: block;
            &:hover {
                color: $primary;
            }
        }

        .footer-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .social {
            display: flex;
            img {
                margin-left: 16px;
            }
        }

        .footer-content {
        }

        .partners, .docs, .company {
            ul li:first-child {
                font-weight: 700;
            }
        }
        .partners, .docs, .company {
            margin-bottom: 35px;
        }
        .payment-logo {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                margin: 0 10px;
            }
        }
        .copyright{
            font-size: 14px;
            text-align: center;
            color: $text-secondary;
            opacity: .8;
        }

        @include breakpoint("md"){
            //.container {
            //    max-width: 90%;
            //    margin: 0 auto;
            //}

            a {
                padding: 2px;
            }
            background: #fff;
            .footer-head {
                flex-direction: column;
                align-items: start;
                .social {
                    margin-top: 22px;
                    img:first-child {
                        margin-left: 0;
                    }
                }
            }
            .footer-content {
                display: flex;
                justify-content: space-between;
                align-items: start;
                hr {
                    display: none;
                }
            }
            .social {
                img {
                    margin-left: 0;
                    margin-right: 16px;
                }
            }
        }

    }


}

// ReDoc
//redoc[spec-url] h1 {
//    color: red;
//}
//
//redoc[spec-url] .tag-info h1 {
//    color: red !important;
//}


::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

///*
// * A better looking default horizontal rule
// */

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

///*
// * Remove the gap between audio, canvas, iframes,
// * images, videos and the bottom of their containers:
// * https://github.com/h5bp/html5-boilerplate/issues/440
// */

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

///*
// * Remove default fieldset styles.
// */

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

///*
// * Allow only vertical resizing of textareas.
// */

textarea {
    resize: vertical;
}

///* ==========================================================================
//   Author's custom styles
//   ========================================================================== */
//
///* ==========================================================================
//   Helper classes
//   ========================================================================== */
//
///*
// * Hide visually and from screen readers
// */

.hidden,
[hidden] {
    display: none !important;
}

.hide {
    opacity: 0;
}

///*
// * Hide only visually, but have it available for screen readers:
// * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
// *
// * 1. For long content, line feeds are not interpreted as spaces and small width
// *    causes content to wrap 1 word per line:
// *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
// */

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    /* 1 */
}

///*
// * Extends the .sr-only class to allow the element
// * to be focusable when navigated to via the keyboard:
// * https://www.drupal.org/node/897638
// */

.sr-only.focusable:active,
.sr-only.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    white-space: inherit;
    width: auto;
}

///*
// * Hide visually and from screen readers, but maintain layout
// */

.invisible {
    visibility: hidden;
}

///*
// * Clearfix: contain floats
// *
// * For modern browsers
// * 1. The space content is one way to avoid an Opera bug when the
// *    `contenteditable` attribute is included anywhere else in the document.
// *    Otherwise it causes space to appear at the top and bottom of elements
// *    that receive the `clearfix` class.
// * 2. The use of `table` rather than `block` is only necessary if using
// *    `:before` to contain the top-margins of child elements.
// */

.clearfix::before,
.clearfix::after {
    content: " ";
    display: table;
}

.clearfix::after {
    clear: both;
}

///* ==========================================================================
//   EXAMPLE Media Queries for Responsive Design.
//   These examples override the primary ('mobile first') styles.
//   Modify as content requires.
//   ========================================================================== */

@media only screen and (min-width: 35em) {
    ///* Style adjustments for viewports that meet the condition */
}

@media print,
(-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 1.25dppx),
(min-resolution: 120dpi) {
    ///* Style adjustments for high resolution devices */
}

///* ==========================================================================
//   Print styles.
//   Inlined to avoid the additional HTTP request:
//   https://www.phpied.com/delay-loading-your-print-css/
//   ========================================================================== */

@media print {
    *,
    *::before,
    *::after {
        background: #fff !important;
        color: #000 !important;
        /* Black prints faster */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]::after {
        content: " (" attr(href) ")";
    }

    abbr[title]::after {
        content: " (" attr(title) ")";
    }

    ///*
    // * Don't show links that are fragment identifiers,
    // * or use the `javascript:` pseudo protocol
    // */
    a[href^="#"]::after,
    a[href^="javascript:"]::after {
        content: "";
    }

    pre {
        white-space: pre-wrap !important;
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    ///*
    // * Printing Tables:
    // * https://web.archive.org/web/20180815150934/http://css-discuss.incutio.com/wiki/Printing_Tables
    // */
    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

